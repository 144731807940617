import {mapMutations} from "vuex";
import {Carousel, Slide} from "vue-carousel";
import productCard from "@/components/product-card/index.vue";

export default {
  name: "related-slider",
  props: {
    list: {
      type: Array,
      default: ()=> []
    }
  },
  components:{
    Carousel,
    Slide,
    productCard
  },
  data(){
    return{
      isDesktop: true,
    }
  },
  created() {
  },
  mounted() {
    window.innerWidth <= 1180 ? this.isDesktop = false : this.isDesktop = true;
  },
  computed:{

  },
  methods:{
    carouselNav($event, direction) {
      const carousel = this.$refs['carousel'];
      carousel.advancePage(direction);
    }
  }
}