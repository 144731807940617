import {mapActions, mapGetters, mapMutations} from "vuex";

import attribute from "@/modules/product/components/attribute/index.vue";
import gallery from "@/modules/product/components/gallery/index.vue";
import relatedSlider from "@/modules/product/components/related-slider/index.vue";
import {error} from "vue-i18n-manager/src/utils";
import {Carousel, Slide} from "vue-carousel";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
//mixins
import {dynamicHead} from '@/mixins/dynamic-head.js'

export default {
  name: "product",
  mixins: [dynamicHead],
  components:{
    attribute,
    gallery,
    relatedSlider,
    Carousel,
    Slide,
    CoolLightBox,
  },
  watch: {
    '$route.params.slug'(newVal) {
      if (newVal) {
        this.resetProduct()
        this.fetchProduct(this.$route.params.slug).then(()=>{
          this.setCrumbs()
          this.setParams()
          this.setMeta()
          this.params.schema = this.product.micro.product
          this.setProductSchema()
          this.payload.product_id = this.product.id
        }).catch(error=>{
          console.log(error)
        })
        this.fetchRelatedProducts(this.$route.params.slug)
        this.counter = 1
      }
    },
    product: {
      immediate: true,
      handler(newVal) {
        this.setCrumbs()
        if( newVal && newVal.attributes?.data?.length && !Object.keys(this.$route.query).length ){
          let attributes = newVal.attributes.data;
          let queryAttributes = {}

          attributes.forEach(item => {
            queryAttributes[item.slug] = item.values.data[0].id
          })

          this.$router.push({query: Object.assign({}, this.$route.query, queryAttributes)}).catch(() => {
            console.log()
          });
        }
      }
    },
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        console.log(newVal.mainLabel);
      }
    },
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if(Object.keys(newVal).length){
          this.getAttributeData();
        }
      }
    },
  },
  data(){
    return{
      descriptionTab: true,
      currentIndex: null,
      mobileGalleryIndex: null,
      counter: 1,
      isDesktop: true,
      payload: {
        product_id: null
      },
      updateCrumbs: false,
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
      ],
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
        schema: null,
      },
    }
  },
  mounted() {
    window.innerWidth <= 1180 ? this.isDesktop = false : this.isDesktop = true;
  },
  created() {
    this.$router.replace({query: null});
    this.fetchProduct(this.$route.params.slug).then(()=>{
      this.setCrumbs()
      this.setParams()
      this.setMeta()
      this.params.schema = this.product.micro.product
      this.setProductSchema()
      this.payload.product_id = this.product.id
    }).catch(error=>{
      console.log(error)
    })
    this.fetchRelatedProducts(this.$route.params.slug)
  },
  computed:{
    ...mapGetters({
      product: 'product/product',
      related: 'product/related',
      productImages: 'product/productImages',
      basketLoading: 'basket/basketLoading',
      isAuthenticated: 'auth/isAuthenticated',
    })
  },
  beforeDestroy() {
    this.$router.replace({query: null});
    this.resetProduct()
  },
  methods:{
    ...mapActions({
      fetchProduct: 'product/GET_PRODUCT',
      fetchRelatedProducts: 'product/GET_RELATED_PRODUCTS',
      fetchProductImages: 'product/GET_PRODUCT_IMAGES',
      addItemToBasket: 'basket/ADD_ITEM_TO_BASKET',
      addFavorite: 'favorites/ADD_FAVORITE',
      removeFavorite: 'favorites/REMOVE_FAVORITE',
    }),
    ...mapMutations({
      resetProduct: 'product/RESET_PRODUCT'
    }),
    setIndex(index){
      this.currentIndex = index
      setTimeout(()=>{
        this.currentIndex = null
      },100)
    },
    changeAttribute(attribute) {
      this.$router.push({
        query: Object.assign({},
          this.$route.query,
          {[attribute.slug]:attribute.data.id })}
      ).catch(() => {
        console.log()
      });
    },
    getAttributeData() {
      this.fetchProductImages({slug: this.$route.params.slug, payload:this.$route.query})
    },
    changeCounter(type){
      type ? this.counter++ : this.counter > 1 ? this.counter-- : this.counter = 1
    },
    addToCart(type){
      this.addItemToBasket({id: this.product.id, attribute_values:  this.$route.query, count: this.counter}).then(()=>{
        this.$toasted.success(this.$t('successAddCart'))
        if(type === true){
          this.$router.push({name: 'order'}).catch(()=>{
            console.log()
          })
        }
      }).catch(error => {
        this.$toasted.error(error)
      })
    },
    setParams(){
      this.params.title = this.product.metaTitle;
      this.params.description = this.product.metaDescription;
      this.params.keywords = this.product.metaKeywords;
      this.params.image = this.product.preview;
    },
    changeFavorite(type){
      type ? this.removeFavorite(this.payload.product_id).then(()=>{this.product.selected = false}) : this.addFavorite(this.payload).then(()=>{this.product.selected = true})
    },
    goSign(){
      this.$router.push({name: 'sign-in'}).catch(()=>{
        console.log()
      })
    },
    setCrumbs(){
      this.crumbs = [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
      ]
      if(this.product && this.product.categories.data.length){
        let categories = this.product.categories.data.map(el => {
          let {title, slug} = el
          return {title, slug}
        })
        categories.forEach(el => {
          el.name = 'catalog'
          this.crumbs.push(el)
        })
        let product = {
          name: 'product',
          title: this.product.title,
          slug: this.product.slug
        }
        this.crumbs.push(product)
        this.updateCrumbs = true
        setTimeout(()=>{
          this.updateCrumbs = false
        },10)
      }
    }
  }
}