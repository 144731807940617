import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from "vue-slide-toggle";

export default {
  name: "attribute",
  props: {
    title: {
      type: String,
      default: ()=> ''
    },
    slug: {
      type: String,
      default: ()=> ''
    },
    slugValue: {
      type: String,
      default: ()=> ''
    },
    list: {
      type: Array,
      default: ()=> []
    }
  },
  components:{
    VueSlideToggle
  },
  watch: {
    slugValue: {
      immediate: true,
      handler(newVal) {
        if( newVal && this.list.length){
          let attributeFind = this.list.find(item => {
            return item.id === Number(newVal);
          });
          this.selectedAttribute = attributeFind ? attributeFind : null;
        }
      }
    },
  },
  data(){
    return{
      open: false,
      selectedAttribute: null,
    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
    })
  },

  methods:{
    ...mapActions({
    }),
    ...mapMutations({
    }),
    changeAttributeValue(){
      this.$emit('change-value', {
        slug: this.slug,
        data: this.selectedAttribute
      });
    },
  }
}