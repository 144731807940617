export default {
  name: "gallery",
  props: {
    list: {
      type: Array,
      default: ()=> []
    },
    current: {
      type: Number,
      default: ()=> null,
    }
  },
  watch: {
    current: {
      immediate: true,
      handler(newVal) {
        if( newVal || newVal === 0 ){
          this.setIndex(this.current);
        }
      }
    },
  },
  data(){
    return {
      currentIndex: null,
    }
  },
  created() {
    this.setIndex(this.current);
  },
  methods:{
    setIndex(index){
      this.currentIndex = index;
    },
  }
}